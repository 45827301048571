export const useSentryConfig = () => {
  const { public: { sentryDsn, shopifyUrl } } = useRuntimeConfig()
  return {
    dsn: sentryDsn,
    environment: shopifyUrl.includes('oats-3') ? 'production' : 'staging',
    // Performance Monitoring
    tracesSampleRate: 0.005,
    // Session Replay
    replaysSessionSampleRate: 0.002,
    replaysOnErrorSampleRate: 0.01
  }
}
